/* Global styles for the entire application */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* New layout styles added to ensure the footer stays at the bottom */
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures body takes up full height of viewport */
}

/* Existing styles for the main App container */
.App {
  flex: 1; /* Makes the App container expand to fill available space */
}

/* Existing styles for code blocks or inline code */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
