/* src/components/Administration.css */
.category {
  margin-bottom: 40px; /* Reset to original */
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 20px; /* Reset padding to the original value */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category h2 {
  font-size: 24px;
  color: #343a40;
  margin-bottom: 20px; /* Reset to original */
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px; /* Reset padding */
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Reset gap between subcategories */
}

.subcategory {
  margin-bottom: 20px; /* Reset to original */
  flex: 1 1 300px; /* Reset item width */
}

.subcategory h3 {
  font-size: 20px;
  color: #6c757d;
  margin-bottom: 10px; /* Reset to original */
}

.items {
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-right: 15px; /* Reset margin */
  margin-bottom: 10px; /* Reset bottom margin for items */
  padding: 10px; /* Reset padding */
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.item img {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 5px;
}

.item a {
  color: #007bff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.2s;
}

.item a:hover {
  color: #0056b3;
}

.item-name {
  color: black;
  font-size: 10px;
  margin-top: 5px;
}
