/* Styling for the entire app container */
.App {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin: 20px;
  background-color: #f8f9fa;
  color: #212529;
}

/* Styling for the page header */
h1 {
  text-align: center;
  color: #343a40;
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: bold;
}

/* Styling for Google AdSense slot */
.google-ad-slot {
  display: block; /* Ensure the ad slot is a block element */
  margin: 0 auto; /* Center the ad */
  height: auto; /* Automatically adjust the height based on the ad content */
  padding: 0; /* Remove any padding */
  min-height: 0; /* Set the minimum height to 0 to prevent reserving unnecessary space */
}

/* Styling for the page footer */
.App-footer {
  margin-top: 40px; /* Adds space above the footer */
  padding: 10px; /* Adds padding inside the footer */
  background-color: #343a40; /* Dark background for the footer */
  color: #ffffff; /* White text color */
  text-align: center; /* Center-aligns the footer text */
  font-size: 14px; /* Sets the font size for the footer text */
  display: flex; /* Use flexbox to align items */
  justify-content: center; /* Center-align items horizontally */
  gap: 20px; /* Adds spacing between footer items */
}

/* Links inside the footer */
.App-footer a {
  color: #ffffff; /* White color for the links */
  text-decoration: underline; /* Underlines the links */
}

/* Adds spacing between specific footer links */
.App-footer a:first-of-type {
  margin-right: 20px; /* Adds spacing to the right of the first link */
}
