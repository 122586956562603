/* src/components/Web.css */
.category {
    margin-bottom: 40px;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .category h2 {
    font-size: 24px;
    color: #343a40;
    margin-bottom: 20px;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 10px;
  }
  
  .subcategories {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .subcategory {
    margin-bottom: 20px;
    flex: 1 1 300px;
  }
  
  .subcategory h3 {
    font-size: 20px;
    color: #6c757d;
    margin-bottom: 10px;
  }
  
  .items {
    display: flex;
    flex-wrap: wrap;
  }
  
  .item {
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .item img {
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 5px;
  }
  
  .item a {
    color: #007bff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: color 0.2s;
  }
  
  .item a:hover {
    color: #0056b3;
  }
  
  .item-name {
    color: black;
    font-size: 10px;
    margin-top: 5px;
  }
  